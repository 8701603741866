import i18n from '../i18n';

export const getLabel = (label, showLabels, isFocused) => {
  // forces floating label when showLabels is false but input field is focused
  if (showLabels || isFocused) return label;
  return '';
};

export const getPlaceholder = (placeholder, isActive) => {
  // removes placeholder from active input field
  if (!isActive) return placeholder;
  return '';
};

const formatFieldLengths = fieldLength => {
  // value or values to concat within Field error message (getCardErrorMsg below)
  if (typeof fieldLength === 'string') return fieldLength;

  // formats array of Field Lengths into pretty string with "or" conjuction and commas
  const clonedFieldLength = [...fieldLength];
  const max = clonedFieldLength.pop();
  const formatedMax = `or ${max.toString()}`;
  const otherFormated = clonedFieldLength.toString().replace(/,/g, ', ');
  return `${otherFormated} ${formatedMax}`;
};

export const getCardErrorMsg = (cardLength, cardErrorMsg) => {
  const validLengths = formatFieldLengths(cardLength);
  if (cardErrorMsg !== undefined) return cardErrorMsg.replace('$1', validLengths);
  return 'No Error Message Defined For Card Number.';
};

export const getPinErrorMsg = (pinLength, pinErrorMsg) => {
  const validLengths = formatFieldLengths(pinLength);
  if (pinErrorMsg !== undefined) return pinErrorMsg.replace('$1', validLengths);
  return 'No Error Message Defined For Pin Number.';
};

/**
 * Show error message under input field if the field has been touched and is in an error state
 * @param {string} field - name of the input field being evaluated (cardNo or pin)
 * @param {string} value - current value of the input field
 * @param {boolean} isEnabledOnBlank - should the error be shown when the field is blank?
 * @param {Array} touched - current boolean values for each field ({"cardNo": false, "pin": false}), has the field been touched?
 * @param {Array} errors - current boolean values for each field ({"cardNo": false, "pin": false}), is the input valid?
 * @returns {boolean} - will the error message be shown?
 */
export const shouldMarkInputError = (field, value, isEnabledOnBlank, touched, focused, errors) => {
  // only shows error is field has error and field has been touched
  if (value == "" && !isEnabledOnBlank) {
    return false;
  }
  return touched[field] && !focused[field] && errors[field] ? true : false;
};

/**
 * Use JavaScript's built in currency formatter (Intl.NumberFormat) to format the card balance based on locale and currency
 * @param {string} lang - locale code for desired language in formatting
 * @param {string} currencyUsed - currency to use in formatting
 * @param {string} currencyDisplay - configure how currency symbol is displayed
 * @param {number} amt - card balance amount
 * @returns {number} Formatted card balance 
 */
export const formatCurrency = (lang, currencyUsed, currDisplay, amt) => new Intl.NumberFormat(lang, { style: 'currency', currency: currencyUsed, currencyDisplay: currDisplay}).format(amt);

/**
 * Format date based on locale using toLocaleDateString
 * @param {string} customNS - custom namespace for translation
 * @param {string} date - transaction date
 * @param {string} dateErrorMsg - error message translation string
 * @param {boolean} isTimestamp - true if date is received as a timestamp, false if it is in a UTC string
 * @param {string} lang - locale code for date formatting
 * @param {object} options - Formatting options for the toLocaleDateString function, defaults to 2 digits for month and day
 * @returns {string} Formatted EOV date
 */
export const formatDate = (
  customNS,
  date,
  dateErrorMsg,
  isTimestamp,
  lang,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }) => {
  try {
    if (isTimestamp) {
      return new Date(date).toLocaleDateString(lang, options);
    } else {
      return new Date(
        date.substring(0, 4), // Year
        date.substring(4, 6) - 1, // Month (zero indexed)
        date.substring(6, 8)) // Day
        .toLocaleDateString(lang, options); 
    }
  } catch {
    return i18n.t(dateErrorMsg, { ns: customNS });
  }
}

export const getTablePageSize = (length, defaultSize) => (length < defaultSize ? length : defaultSize);

export const iOS = () => {
  if (/Mac|iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const getContextPath = () => window.location.pathname.split('/').find((value, index) => {
  if (index === 1) return value;
  return undefined;
});

// @flow
import React from "react";
import classnames from "classnames";
import ReactLoading from "react-loading";

const LoadingSpinner = props => (
  <div className={classnames("container-fluid", props.order)}>
    <div className="d-flex justify-content-center align-items-center load-spinner">
      <ReactLoading type="spin" color="#2A393F" height={50} width={75} />
    </div>
  </div>
);

export default LoadingSpinner;

import React from "react";
import i18n from "../../i18n";

const Error = () => (
  <div className="d-flex flex-column error-container">
    <div className="d-flex flex-row justify-content-center error-content">
      <p className="display-4 error-pgh">
        {i18n.t("Oh, no! Something went wrong.")}
        <br />
        {i18n.t("Please try again later.")}
      </p>
    </div>
  </div>
);

export default Error;

/*
  Demo Component Routes
  * Template examples
*/

import React from "react";
import { Route } from "react-router-dom";
import Loadable from "jaybe-react-loadable";
import LoadingSpinner from "../modules/loadingSpinner";

const LoadingComponent = () => <LoadingSpinner />;

const TemplatesPage = Loadable({
  loader: () => import("../prebuilt/preview/templatesPreview"),
  loading: LoadingComponent
});

const BasicStyled = Loadable({
  loader: () => import("../prebuilt/basicStyled"),
  loading: LoadingComponent
});

const BasicUnstyled = Loadable({
  loader: () => import("../prebuilt/basicUnstyled"),
  loading: LoadingComponent
});

const Detailed = Loadable({
  loader: () => import("../prebuilt/basicDetailed"),
  loading: LoadingComponent
});

const WithCardStyled = Loadable({
  loader: () => import("../prebuilt/cardStyled"),
  loading: LoadingComponent
});

const WithCardNoIcon = Loadable({
  loader: () => import("../prebuilt/cardUnstyledNoIcon"),
  loading: LoadingComponent
});

const WithCard = Loadable({
  loader: () => import("../prebuilt/cardUnstyled"),
  loading: LoadingComponent
});

const WithCardSP = Loadable({
  loader: () => import("../prebuilt/cardUnStyledSidePanel"),
  loading: LoadingComponent
});

const WithCardStyledSP = Loadable({
  loader: () => import("../prebuilt/cardStyledSidePanel"),
  loading: LoadingComponent
});

const WithGiftCard = Loadable({
  loader: () => import("../prebuilt/giftCardUnstyled"),
  loading: LoadingComponent
});

const WithGiftCardStyled = Loadable({
  loader: () => import("../prebuilt/giftCardStyled"),
  loading: LoadingComponent
});

const iframeCUWI = Loadable({
  loader: () => import("../prebuilt/iframeCUWI"),
  loading: LoadingComponent
});

const TemplatesDemo = () => (
  <React.Fragment>
    <Route exact path={`/${window.env.contextPath}/templates`} component={TemplatesPage} />
    <Route exact path={`/${window.env.contextPath}/templates/basic`} component={BasicUnstyled} />
    <Route exact path={`/${window.env.contextPath}/templates/basicStyled`} component={BasicStyled} />
    <Route exact path={`/${window.env.contextPath}/templates/detailed`} component={Detailed} />
    <Route exact path={`/${window.env.contextPath}/templates/withCardStyled`} component={WithCardStyled} />
    <Route exact path={`/${window.env.contextPath}/templates/withCard`} component={WithCard} />
    <Route exact path={`/${window.env.contextPath}/templates/withCardNoIcon`} component={WithCardNoIcon} />
    <Route exact path={`/${window.env.contextPath}/templates/withCardSP`} component={WithCardSP} />
    <Route exact path={`/${window.env.contextPath}/templates/withCardStyledSP`} component={WithCardStyledSP} />
    <Route exact path={`/${window.env.contextPath}/templates/withGiftCard`} component={WithGiftCard} />
    <Route exact path={`/${window.env.contextPath}/templates/withGiftCardStyled`} component={WithGiftCardStyled} />
    <Route exact path={`/${window.env.contextPath}/templates/iframeCUWI`} component={iframeCUWI} />
  </React.Fragment>
);

export default TemplatesDemo;

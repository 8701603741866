/*
  Index Routing Class 
    * defines root client route (${contextPath}/clients)
    * returns Client Routing Class (defines each client's individual route)
*/
// @flow

import React from "react";
import axios from "axios";
import { Route, Switch } from "react-router-dom";

import { withTranslation } from "react-i18next";
import localeCode from "iso-639-1";
import ClientInfo from "../context/clientInfo";
import NotFound from "../modules/notFound";
import Error from "../modules/error";

import Client from "./client";
import TemplatesDemo from "./templatesDemo";
import LoadingSpinner from "../modules/loadingSpinner";
import i18n from "../../i18n";
import { getContextPath } from "../../helpers/formatUtils";

type Props = {};

type State = {
  host: string,
  language: string,
  prefix: string,
  showPin: boolean | string,
  showCaptcha: number,
  showError: boolean | string,
  notFound: boolean | string,
  loading: boolean | string
};

const getEnvJS = () => {
  const contextPath = getContextPath();
  return new Promise((resolve, reject) => {
    axios
      .get(`/${contextPath}/config/env.js`)
      .then(response => {
        window.env = response.data;
        window.env.contextPath = contextPath;
        resolve("env loaded");
      })
      .catch(() => {
        reject(new Error("env not loaded"));
      });
  });
};

class Routes extends React.Component<Props, State> {
  state = {
    host: "",
    language: "",
    prefix: "",
    showPin: false,
    showCaptcha: 2, // default invisible
    showError: false,
    notFound: false,
    loading: true
  };

  componentDidMount(): void {
    const ENV = process.env.NODE_ENV;

    getEnvJS()
      .then(() => {
        this.setState({ loading: false });

        // when you want to use demo client info for development
        if (ENV !== "production" && process.env.REACT_APP_SKIP_CLIENT_INFO === "TRUE") {
          return this.setState({
            host: process.env.REACT_APP_CLIENT,
            language: process.env.REACT_APP_LANGUAGE,
            prefix: process.env.REACT_APP_PREFIX,
            showPin: true
          });
        } else if (window.location.pathname.startsWith(`/${window.env.contextPath}/clients/`)) {
          const client = window.location.pathname.split("/").find((value, index) => {
            if (index === 3) return value;
            return undefined;
          });

          const urlParams = new URLSearchParams(window.location.search);
          let lang = urlParams.get("lng");
          lang = lang !== null ? lang.trim() : lang;

          // validate language and set to null if not valid
          // not validating Austria because it can be different from German
          if (lang !== null && lang !== "at"  && !localeCode.validate(lang)) lang = null;
          // set i18n language
          i18n.changeLanguage(lang);

          // validate client
          if (!client) {
            return this.setState({ notFound: true });
          }

          const clientInfoUrl = `/${window.env.contextPath}/api/clientInfo/${client}`;
          // call clientInfo
          axios
            .get(clientInfoUrl)
            .then(response => {
              if (response.status === 200) {
                if (response.data.messageReturnCode === "1000") {
                  const clientData = response.data.resultList[0];
                  const language = lang || clientData.language;
                  // set i18n and recaptcha language
                  i18n.changeLanguage(language);
                  window.recaptchaOptions = { lang: language };
                  return this.setState({
                    host: client,
                    prefix: clientData.prefix,
                    language,
                    showPin: !!clientData.pinId, // coerce Number to Boolean
                    showCaptcha: clientData.captchaId
                  });
                } else if (response.data.messageReturnCode === "1001") {
                  return this.setState({ notFound: true });
                }
              }
              return this.setState({ showError: true });
            })
            .catch(() => this.setState({ showError: true }));
        } else if (window.location.pathname.startsWith(`/${window.env.contextPath}/templates`)) {
          if (window.env && window.env.show_demo && window.env.show_demo === "true") {
            return this.setState({
              showPin: true
            });
          } else {
            return this.setState({ notFound: true });
          }
        }
      })
      .catch(() => this.setState({ showError: true }));
  }

  render() {
    const { notFound, showError, language, loading, prefix } = this.state;
    if (loading) return <LoadingSpinner />;
    if (notFound) return <NotFound />;
    return (
      <ClientInfo.Provider
        value={{
          clientInfo: this.state
        }}
      >
        {showError ? (
          <Error />
        ) : (
          <Switch>
            <Route
              path={`/${window.env.contextPath}/clients/*`}
              render={({ history }) => <Client history={history} prefix={prefix} language={language} />}
            />
            <Route path={`/${window.env.contextPath}/templates`} render={() => <TemplatesDemo />} />
            <Route component={NotFound} />
          </Switch>
        )}
      </ClientInfo.Provider>
    );
  }
}

export default withTranslation()(Routes);

import React from "react";
import i18n from "../../i18n";

export default ({ staticContext = {} }) => {
  staticContext.status = 404;
  return (
    <div className="light-grey-text roboto d-flex justify-content-center notfound-container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <i className="far fa-5x fa-frown" />
        <h1 className="display-2">404</h1>
        <h2 className="h3">{i18n.t("Page not found")}</h2>
        <p>
          {i18n.t(
            "The page you are looking for doesn't exist, or an error occured."
          )}
        </p>
      </div>
    </div>
  );
};

// must import core-js and runtime first - allows async in ie
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";

import "./style.css";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import App from "./components/App";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

